.login-form {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  margin: 20px;
}

.level-indicator, .rate-indicator, .timer, .schedule {
  margin-top: 20px;
  align-self: flex-start;
  justify-self: center;
}

.sensor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.control-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.status {
  width: auto;
  padding: 5px;
  text-align: center;
  font-size: larger;
  margin-left: auto;
}

.powerBtn {
  border: none;
  font-size: 10rem;
  align-self: flex-start;
  justify-self: center;
  margin-top: 20px;
}

.disableBtn {
  border: none;
  font-size: 5rem;
  align-self: flex-start;
  justify-self: center;
  margin-top: 20px;
}

.panel {
  text-align: center;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 80%;
  gap: 40px;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 80%;
  height: 90%;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.App {
  user-select: none;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.card-title {
  display: flex;
  position: relative;
  flex-direction: row;
}

@media screen and (min-width: 768px) {
  .panel {
    flex-direction: row;
    width: 100%;
  }

  .dashboard {
    width: 60%;
  }
}

#root {
  height: 100%;
  width: 100%;
}

body {
  background-color: #FFF;
  color: #111;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #111;
    color: white
  }
}
